<template>
  <div>
    <!-- <div class="flex justify-content-between"> -->
    <!-- <Button
        class="mr-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
        <i class="pi pi-facebook text-indigo-500 mr-2"></i>
        <span>Sign in With Facebook</span>
      </Button>
      <Button
        class="ml-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
        <i class="pi pi-google text-red-500 mr-2"></i>
        <span>Sign in With Google</span>
      </Button> -->
    <!-- </div> -->
    <!-- <Divider align="center" class="my-4">
      <span class="text-600 font-normal text-sm">OR</span>
    </Divider> -->
    <form @submit.prevent="onSubmit">
      <div class="formgrid grid">
        <div class="field col-12 mb-6">
          <span class="text-2xl font-medium text-900">Přihlásit se do Pixiu Systems</span>
        </div>
        <div class="field col-12">
          <label
            for="email"
            class="text-900 font-medium mb-2"
          >Email</label>
          <InputText
            v-model="formData.credentials.email"
            input-id="email"
            type="text"
            placeholder="Emailová adresa"
            autocomplete="username"
            required
            class="w-full"
          />
          <small
            id="text-error"
            class="p-error"
          >{{ (formData.errorBag.email && formData.errorBag.email[0]) || '&nbsp;'
          }}</small>
        </div>
        <div class="field col-12">
          <label
            for="password"
            class="text-900 font-medium"
          >Heslo</label>
          <Password
            v-model="formData.credentials.password"
            input-id="password"
            placeholder="Heslo"
            :feedback="false"
            toggle-mask
            input-class="w-full"
            class="w-full"
            :input-props="{ autocomplete: 'current-password' }"
            required
          />
          <small
            id="text-error"
            class="p-error"
          >{{ (formData.errorBag.password && formData.errorBag.password[0]) ||
            '&nbsp;' }}</small>
        </div>
        <div class="field-checkbox col-12">
          <Checkbox
            v-model="formData.credentials.remember"
            input-id="rememberme"
            binary
            class="mr-2"
          />
          <label for="rememberme">Zapamatovat si mě (60 dnů)</label>
        </div>
        <div class="field col-12">
          <NuxtLink
            class="cursor-pointer transition-colors transition-duration-150 text-primary hover:text-primary-300 font-semibold no-underline"
            :to="{ name: 'login-forgot-password' }"
          >
            Zapomněli jste heslo?
          </NuxtLink>
        </div>
        <div class="field col-12 mt-4">
          <Button
            label="Přihlásit se"
            class="w-full py-3 font-medium"
            type="submit"
            raised
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
const { signIn } = useAuth();
const toast = useGlobalToast();
const route = useRoute();

const formData = reactive({
  credentials: {
    email: null,
    password: null,
    remember: false,
    redirect: false,
    callbackUrl: route.query.callbackUrl
  },
  errorBag: {
    email: [null],
    password: [null]
  }
});

async function onSubmit(event) {
  const { error, url } = await signIn('credentials', formData.credentials);

  if (error) {
    // Do your custom error handling here
    // formData.errorBag = error.value.data.errors;
    toast.add({
      severity: 'error',
      summary: 'Nesprávné přihlašovací údaje.',
      detail: 'Zkontrolujte email, heslo a jestli nemáte zapnutý caps lock.',
      life: 3000
    });
  } else {
    // No error, continue with the sign in, e.g., by following the returned redirect:
    navigateTo(url, { external: true });
  }
}

</script>
